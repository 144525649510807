import React from "react";
import { FaWhatsapp } from "react-icons/fa"; // Importando o ícone do WhatsApp
import "./App.css";

const App = () => {
  
  const phoneNumber = "5586999060047"; // Substitua pelo número de telefone desejado

  return (
    <div>
      <div className="main">
        {/* Adicione qualquer conteúdo adicional se necessário */}
      </div>

      <div className="bot" style={{ paddingBottom: "5rem", paddingRight:"1rem" }}>
        {/* Botão de redirecionamento para o WhatsApp com ícone */}
        <button 
          className="btn" 
          style={{
            backgroundColor: "#fdfdfd", 
            borderRadius: "50%", 
            width: "80px", 
            height: "80px", 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            //border: "2px solid #25D366",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.29)",
            paddingBottom: "1rem"
          }}
        >
          <a 
            href={`https://api.whatsapp.com/send?phone=${phoneNumber}`} 
            target="_blank" 
            rel="noopener noreferrer"
            style={{
              display: "flex", 
              justifyContent: "center", 
              alignItems: "center", 
              textDecoration: "none"
            }}
          >
            <FaWhatsapp size={40} color="#25D366" />
          </a>
        </button>
      </div>
    </div>
  );
};

export default App;
